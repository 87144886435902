
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































.flexible-followup__feedback {
  @include default-margin(0.7);
}

.flexible-followup__steps {
  @extend %list-nostyle;
  @include default-margin(0.7);

  display: grid;
  grid-gap: 3rem;

  @include mq(s) {
    grid-template-columns: repeat(3, col(3, 10));
    grid-gap: col(0.5, 10);
  }
}

.flexible-followup__step {
  color: $c-medium-grey;

  @include mq(l) {
    font-size: 1.8rem;

    .title {
      font-size: 2.4rem;
    }
  }
}

.g-label--yellow {
  @extend %ff-alt;

  display: flex;
  align-items: center;

  &::after {
    content: '';
    flex: 1;
    width: 100%;
    height: 1px;
    margin-left: math.div($spacing, 2);
    background: $c-dark-grey;
  }
}

.flexible-followup__step__time {
  @extend %fw-medium;

  display: flex;
  margin-top: $spacing;
  color: $c-dark-grey;

  .icon {
    flex-shrink: 0;
    margin-right: math.div($spacing, 2);
    color: $c-mustard;
  }
}
